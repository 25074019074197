import React from "react";
import { Modal } from "react-bootstrap";
import "./Fullscreenmodal.css";
// import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { StyledTypography } from "../../../../utils/StyledTypographyHeadings";

const applicationMode = localStorage.getItem("application_mode");

function CircularProgressWithLabel(props) {
  
  console.log("percentage value", props.value);
  
  function checkNumberType(num){
    // if (isNaN(num)) {
    //   return 100;
    // }
    
    // // Check if the number is Positive Infinity or Negative Infinity
    // if (num === Number.POSITIVE_INFINITY || num === Number.NEGATIVE_INFINITY) {
    //   return 100;
    // }
    
    // If none of the above conditions are met, round the number
    return Math.round(num);
  
  }
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        size={80}
        thickness={3.6}
        sx={{ color: applicationMode === "light" ? "#0b3aff" : "#ffffff" }}
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StyledTypography
          variant="h6"
          sx={{ color: applicationMode === "light" ? "#000000" : "#ffffff" }}
        >
          {`${checkNumberType(props.value)}%`}
        </StyledTypography>
      </Box>
    </Box>
  );
}

const DataUploadingModal = ({ isModalOpenResize, uploadProgress }) => {
  return (
    <Modal
      show={isModalOpenResize}
      onHide={null}
      backdrop="static"
      disableEscapeKeyDown
      disableBackdropClick
    >
      <Modal.Body style={{ border: "1px solid red" }}>
        <div>
          <h2>Data Upload in Progress</h2>
          <div>
            <CircularProgressWithLabel value={uploadProgress ?? 0} />
          </div>
          <p>
            Slow internet was detected on your side, so 
            Please wait until your complete data is uploaded.
            <br />
            <br />
            This process may take a few moments to finish. Thank you for your
            patience and understanding. Please refrain from closing this window
            or interacting with the application until the upload is complete.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DataUploadingModal;
