export default function getOptimizedStreamConstraints(fetchedoverviewdata) {
  // Get the screen's pixel density (device pixel ratio)
  const devicePixelRatio = window.devicePixelRatio;
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  let videoBitsPerSecond = 1000000; // Moderate bitrate for medium quality
  let audioBitsPerSecond = 64000; // Audio bitrate
  let frameRate = 20; // Moderate FPS for medium quality

  // Adjust settings based on device pixel ratio
  if (devicePixelRatio === 1) {
    // Pixel ratio = 1 (Standard displays) – use medium quality settings
    videoBitsPerSecond = 800000; // Moderate bitrate
  } else if (devicePixelRatio > 1 && devicePixelRatio <= 6) {
    // Pixel ratio > 1 and <= 6 (Retina-like or decent DPI displays) – use decent quality settings
    videoBitsPerSecond = 900000; // Higher bitrate for decent quality
  } else {
    // Pixel ratio > 6 (High-DPI screens like advanced Retina) – use best quality settings
    videoBitsPerSecond = 2000000; // Highest bitrate for best quality
    frameRate = 25; // Maximum FPS for best quality
  }

  if(fetchedoverviewdata && (fetchedoverviewdata?.setting?.allowscreenrecording == 'true')){
    videoBitsPerSecond = videoBitsPerSecond / 2; // Moderate bitrate for medium quality
    frameRate = frameRate / 2; // Moderate FPS for medium quality
  }

  return { videoBitsPerSecond, audioBitsPerSecond, frameRate, screenWidth, screenHeight, devicePixelRatio };
}
