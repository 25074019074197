import axios from "axios";
import React, { useRef, useEffect, useState } from "react";
// import { AiOutlineClose } from "react-icons/ai";
import Webcam from "react-webcam";
// import { useFaceDetection } from "react-use-face-detection";
import { FaceDetection } from "@mediapipe/face_detection";
// import { Camera } from "@mediapipe/camera_utils";
import { handlecamerapermission } from "../../../../Redux/Checkpermissions/Permission";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import { useParams } from "react-router-dom";

const Camerafortest = ({
  setwebcamRef,
  sendLog,
  openmodalForCamera,
  closemodalForCamera,
  tokenid,
  setFacesDetected,
  isSubmitting,
  sessionId,
}) => {
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [faceStatus, setFaceStatus] = useState("Looking for faces...");
  const [snapshots, setSnapshots] = useState([]);
  const lastCaptureTimeRef = useRef(0);
  const captureInterval = 30000;
  const [faceFlag, setFaceFlag] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const detectionHistory = useRef([]);
  const healthCheckInterval = 10000;
  const closeModalFunctionRef = useRef(null);

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initTimer = setTimeout(() => {
      setIsInitialized(true);
    }, 5000);

    return () => clearTimeout(initTimer);
  }, []);

  const localstoragedata = JSON.parse(localStorage.getItem("userdetails"));

  const fetchedsetting = useAppSelector(
    (state) => state?.tests?.fetchedsetting
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 1000);

    const faceDetection = new FaceDetection({
      locateFile: (file) =>
        `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
    });

    faceDetection.setOptions({
      model: "short", // or "full"
      minDetectionConfidence: 0.5,
    });

    faceDetection.onResults((results) => {
      try {
        const numFaces = results.detections.length;

        // Smoothing detection results with a history buffer
        detectionHistory.current.push(numFaces);
        if (detectionHistory.current.length > 5) {
          detectionHistory.current.shift(); // Keep last 5 results
        }

        const averageNumFaces =
          detectionHistory.current.reduce((a, b) => a + b, 0) /
          detectionHistory.current.length;

        // Single face is detected
        if (averageNumFaces === 1) {
          setFaceFlag(1);
          setFaceStatus("Single face detected");
          captureFace(averageNumFaces);
        }
        // No faces detected
        else if (averageNumFaces === 0) {
          setFaceFlag(0);
          setFaceStatus("No face detected");
          captureFace(averageNumFaces);
        }
        // Multiple faces detected
        else if (averageNumFaces > 1) {
          setFaceFlag(averageNumFaces);
          captureFace(averageNumFaces);
          setFaceStatus(`Multiple faces detected (${averageNumFaces})`);
        }

        // Draw the webcam frame onto the canvas
        if (canvasRef.current) {
          const canvasCtx = canvasRef.current.getContext("2d");
          const videoImage = webcamRef.current.getScreenshot(); // Capture the current frame from the webcam
          const img = new Image();
          img.src = videoImage;
          img.onload = () => {
            canvasCtx.clearRect(
              0,
              0,
              canvasRef.current.width,
              canvasRef.current.height
            );
            canvasCtx.drawImage(
              img,
              0,
              0,
              canvasRef.current.width,
              canvasRef.current.height
            );
          };
        }
      } catch (error) {
        console.error("Error processing face detection results:", error);
      }
    });

    let isProcessing = false;

    const intervalId = setInterval(async () => {
      if (isReady && !isProcessing && webcamRef.current) {
        isProcessing = true;
        try {
          const canvas = webcamRef.current.getCanvas();
          if (canvas) await faceDetection.send({ image: canvas });
        } catch (error) {
          console.error(error);
        } finally {
          isProcessing = false;
        }
      }
    }, 100);

    return () => {
      clearTimeout(timer);
      clearInterval(intervalId);
    };
  }, [isReady]);

  useEffect(() => {
    if (webcamRef) {
      setwebcamRef(webcamRef);
    }
  }, [webcamRef]);


  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    if (faceFlag < 1 && faceStatus !== "Looking for faces...") {
      // console.log("openmodalForCamera");
      openmodalForCamera();
      closeModalFunctionRef.current = 1;
    } else if (faceFlag > 1.9) {
      openmodalForCamera();
      closeModalFunctionRef.current = 1;
    } else {
      if (closeModalFunctionRef.current === 1) {
        closemodalForCamera();
        closeModalFunctionRef.current = 0;
      }
    }
    setFacesDetected(faceFlag);
  }, [
    webcamRef,
    faceFlag,
    openmodalForCamera,
    closemodalForCamera,
    setFacesDetected,
    faceStatus,
    isInitialized,
  ]);

  useEffect(() => {
    const healthCheck = setInterval(() => {
      // Check if the webcam is streaming or frozen
      if (
        webcamRef.current &&
        webcamRef.current.video &&
        !webcamRef.current.video.paused &&
        !webcamRef.current.video.ended
      ) {
        console.log("Camera stream is healthy");
      } else {
        console.log("Camera stream is frozen, restarting...");

        // Stop all tracks of the webcam stream
        if (webcamRef.current.stream) {
          webcamRef.current.stream.getTracks().forEach((track) => track.stop());
        }

        // Clear the video element's srcObject
        webcamRef.current.video.srcObject = null;

        // Restart the webcam stream by setting state or rerendering the component
        setIsReady(false);
        setTimeout(() => {
          setIsReady(true); // or some logic that reinitializes the webcam
        }, 1000); // Wait a moment before restarting to avoid rapid retries
      }
    }, healthCheckInterval);

    return () => clearInterval(healthCheck);
  }, [webcamRef, healthCheckInterval]);

  // const sendScreenshot = (imageUrl, type) => {
  //   try {
  //     if (type < 1) {
  //       const payload = {
  //         user_image: imageUrl,
  //         remark: "no faces detected",
  //       };

  //       if (imageUrl) {
  //         if (tokenid) {
  //           return null;
  //         }

  //         if (fetchedsetting?.randomphotos == "true") {
  //           axios
  //             .post(`/getexamdata/userimages/${id}`, payload)
  //             .then((res) => {
  //               if (
  //                 res.data.result === "Rows matched: 1  Changed: 1  Warnings: 0"
  //               ) {
  //                 // window.location.href = "/testoverview/404";
  //               }
  //             })
  //             .catch((Error) => {});
  //         }
  //       }
  //     } else if (type > 1.9) {
  //       const payload = {
  //         user_image: imageUrl,
  //         remark: "too many faces detected",
  //       };

  //       if (tokenid) {
  //         return null;
  //       }

  //       if (imageUrl) {
  //         if (fetchedsetting?.randomphotos == "true") {
  //           axios
  //             .post(`/getexamdata/userimages/${id}`, payload)
  //             .then((res) => {})
  //             .catch((Error) => {});
  //         }
  //       }
  //     } else {
  //       const payload = {
  //         user_image: imageUrl,
  //       };
  //       if (imageUrl) {
  //         if (tokenid) {
  //           return null;
  //         }

  //         if (fetchedsetting?.randomphotos == "true") {
  //           axios
  //             .post(`/getexamdata/userimages/${id}`, payload)
  //             .then((res) => {})
  //             .catch((Error) => {});
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error sending image:", error);
  //   }
  // };

  const sendScreenshot = async (imageUrl, type) => {
    try {
      if (!imageUrl || tokenid || fetchedsetting?.randomphotos == "false") {
        if (!imageUrl) {
          console.log("imageUrl is null");
        }
        if (tokenid) {
          console.log("capture photo is not for preview test viewer");
        }
        if (fetchedsetting?.randomphotos == "false") {
          console.log("random photos is not enabled");
        }
        return null;
      }

      if(Number(sessionId) === 0 || sessionId === null) { 
        console.log("sessionId is null or 0");
        return null;
      }
  
      let payload = {
        user_image: imageUrl,
        sessionId: sessionId,
      };
  
      if (type < 1) {
        payload.remark = "no faces detected";
      } else if (type > 1.9) {
        payload.remark = "too many faces detected";
      }
  
      const retryRequest = async (url, payload, retries = 8, delay = 1000) => {
        for (let attempt = 1; attempt <= retries; attempt++) {
          try {
            // Perform the API call
            const response = await axios.post(url, payload);
            return response;
          } catch (error) {
            if (attempt === retries) {
              console.error("Max retries reached. Error:", error);
              throw error; // Re-throw the error after max retries
            }
            console.warn(`Retrying (${attempt}/${retries})...`);
            await new Promise((resolve) => setTimeout(resolve, delay)); // Wait before retrying
          }
        }
      };
      
      console.log("calling the api here");

      const url = `/getexamdata/userimages/${id}`;

      console.log("inside image capture checking isSubmitting", isSubmitting);

      if(!isSubmitting){
        await retryRequest(url, payload);
      }
    } catch (error) {
      console.error("Error sending image:", error);
    }
  };

  const captureFace = (currentFaceFlag) => {
    const currentTime = Date.now();
    if (currentTime - lastCaptureTimeRef.current < captureInterval) return; // Capture every 3 seconds
    lastCaptureTimeRef.current = currentTime;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Draw the current webcam frame into the canvas
    context.drawImage(
      webcamRef.current.getCanvas(),
      0,
      0,
      canvas.width,
      canvas.height
    );

    // Convert the canvas content to an image URL
    const faceImageURL = canvas.toDataURL("image/png");

    // Store the snapshot
    setSnapshots((prevSnapshots) => [...prevSnapshots, faceImageURL]);
    
    sendScreenshot(faceImageURL, currentFaceFlag);
  };

  const camera_permission = (status) => {
    dispatch(handlecamerapermission(status));
  };

  return (
    <div className="refreshgif2">
      <Webcam
        ref={webcamRef}
        audio={false}
        screenshotFormat="image/jpeg"
        width={320}
        height={320}
        videoConstraints={{ facingMode: "user", frameRate: 15 }}
        onUserMedia={() => {
          camera_permission(true);
        }}
        onUserMediaError={() => {
          camera_permission(false);
        }}
      />

      <canvas
        ref={canvasRef}
        width={320}
        height={320}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default Camerafortest;
